import { useState } from "react";
import axios from "axios";
import "./App.css";
import Marquee from "react-fast-marquee";
import { GoLocation } from "react-icons/go";
import Menu from "./Menu.js";
import sunny from "./pics/sunnysideup.png"; // with import
import partlycloudy from "./pics/partlycloudy.png"; // with import
import cloudy from "./pics/cloudy.png"; // with import
import moon from "./pics/bluemoon.png";
import lightrain from "./pics/light-rain.png";
const WEATHER_API = process.env.REACT_APP_WEATHER_API_URL;

function MarqueeQuip({ text }) {
  return (
    <div style={{ width: "100%", paddingLeft: "1%" }}>
      <h3>
        &nbsp;{" ✧ "}&nbsp;&nbsp;&nbsp;&nbsp;{text}
      </h3>
    </div>
  );
}
function App() {
  // new line start
  const [weatherData, setWeatherData] = useState(null);
  const [searchInput, setSearchInput] = useState(0);
  const [name, setName] = useState("");
  const [daytime, setDayTime] = useState(1);

  const getWeatherData = (name) => {
    axios({
      method: "GET",
      url: `${WEATHER_API}/weather/${name}`,
    })
      .then((response) => {
        const res = response.data;
        console.log(res);
        setDayTime(res.current.is_day);
        const arr = res.location.localtime.split(" ");
        console.log(arr);
        setWeatherData({
          name: res.location.name,
          temp_f: res.current.temp_f,
          condition: res.current.condition.text,
          is_day: res.current.is_day,
          date: arr[0],
          time: arr[1],
          region: res.location.region,
          country: res.location.country,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  return (
    <div className="App">
      <Marquee pauseOnHover="true" gradient={false}>
        <MarqueeQuip text="On cloud 9" />
        {/* <MarqueeQuip text="Ordering food on this app: a little chili today and extra sprinkles please"/> */}
        <MarqueeQuip text="Hows's the weather down there?" />
        <MarqueeQuip text="You are so foggin' cute!" />
        <MarqueeQuip text="I mist you" />
        <MarqueeQuip text="Why are the clouds always throwing shade?" />
        <MarqueeQuip
          text="This weather is SNOW
          laughing matter !"
        />
        {/* 
        <h3>
        How's the weather down there? • You are so foggin' cute! • I mist you{" "}
          • Why are the clouds always throwing shade? • This weather is SNOW
          laughing matter 
        </h3> */}
      </Marquee>
      {/* <Menu/> */}
      <header className={daytime === 1 ? "App-header-day" : "App-header"}>
        <h1>Weather Machine</h1>
        <label>
          Search City or Zip Code:
          <div id="cover">
            <div class="tb">
              <div class="td">
                <input
                  type="text"
                  placeholder="Search"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  required
                />
              </div>
              <div class="td" id="s-cover">
                <button
                  type="submit"
                  onClick={() => {
                    getWeatherData(name);
                  }}
                >
                  <div id="s-circle"></div>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
          {/* <input
            type="text"
            className="searchTerm"
            value={name}
            placeholder="Seoul or 87109"
            onChange={(e) => {
              setName(e.target.value);
            }}
          /> */}
        </label>
        {/* <button
          type="submit"
          onClick={() => {
            getWeatherData(name);
          }}
        >
          Click me
        </button> */}
        {weatherData && (
          <div>
            <p>{weatherData.temp_f}°F</p>
            <p>
              {<GoLocation />}
              {weatherData.name},{" "}
              {weatherData.region ? weatherData.region : weatherData.country}
            </p>
            {weatherData.condition === "Partly cloudy" && (
              <img src={partlycloudy} className="App-logo"/>
            )}
            {weatherData.condition === "Sunny" && <img src={sunny} className="App-logo"/>}
            {weatherData.condition === "Overcast" && <img src={cloudy} className="App-logo"/>}
            {weatherData.condition === "Light rain" && <img src={lightrain} className="App-logo"/>}
            {weatherData.condition === "Heavy rain" && <img src={lightrain} className="App-logo" />}
            {weatherData.condition === "Clear" && weatherData.is_day === 0 && (
              <img src={moon} className="App-logo"/>
            )}
            <p>
              {weatherData.time}{" "}
              {parseInt(
                weatherData.time.substring(0, weatherData.time.indexOf(":"))
              ) >= 0 &&
              parseInt(
                weatherData.time.substring(0, weatherData.time.indexOf(":"))
              ) <= 11
                ? "AM"
                : "PM"}{" "}
            </p>
            <p>{weatherData.condition}</p>
            <>
            {/* <h6>Powered by:</h6> */}
              <a href="https://www.weatherapi.com/" title="Free Weather API">
                <img
                  src="//cdn.weatherapi.com/v4/images/weatherapi_logo.png"
                  alt="Weather data by WeatherAPI.com"
                  border="0"
                />
              </a>
            </>
          </div>
        )}
        {/* end of new line */}
      </header>
    </div>
  );
}

export default App;
